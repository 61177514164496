import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				InkCraft Tattoo Studio
			</title>
			<meta name={"description"} content={"Розкрийте свій внутрішній артистизм"} />
			<meta property={"og:title"} content={"InkCraft Tattoo Studio"} />
			<meta property={"og:description"} content={"Розкрийте свій внутрішній артистизм"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-2.jpg?v=2024-05-24T11:37:06.770Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-2.jpg?v=2024-05-24T11:37:06.770Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-2.jpg?v=2024-05-24T11:37:06.770Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-2.jpg?v=2024-05-24T11:37:06.770Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-2.jpg?v=2024-05-24T11:37:06.770Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-2.jpg?v=2024-05-24T11:37:06.770Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665047916bbd2000236ad97d/images/2-2.jpg?v=2024-05-24T11:37:06.770Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				InkCraft Tattoo Studio
			</Override>
		</Components.Header>
		<Section padding="100px 0 100px 0" background="--color-dark" quarkly-title="FAQ-4">
			<Text margin="0px 0px 15px 0px" font="--headline1" color="--light">
				Питання що часто задаються
			</Text>
			<Text margin="0px 0px 70px 0px" font="--base" color="--light" sm-margin="0px 0px 50px 0px">
				Тут ми зібрали деякі з найпоширеніших запитань, щоб надати вам потрібну інформацію. Якщо у вас все ще є запитання, наш привітний персонал завжди готовий допомогти вам.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						Як записатися на прийом?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Забронювати зустріч з нами легко. Ви можете або зателефонувати нам, або написати нам. Наша команда допоможе вам запланувати консультацію з одним із наших талановитих художників, щоб обговорити ваші ідеї татуювань.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						Чого мені очікувати під час консультації?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Під час консультації ви зустрінетеся з одним із наших художників, щоб обговорити ваш дизайн татуювання, розташування та будь-які ваші конкретні вподобання. Ми також надамо приблизний час і витрати. Це чудова нагода поставити будь-які запитання та переконатися, що процес вам комфортний.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						Як мені підготуватися до сеансу татуювання?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Ми рекомендуємо вам добре виспатися, поїсти здорову їжу та зволожити перед сеансом татуювання. Уникайте алкоголю та кофеїну в день вашого призначення. Носіть зручний одяг, який забезпечує легкий доступ до місця, де ви будете робити татуювання.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						Чи боляче робити тату?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Рівень болю залежить від розташування та розміру татуювання, а також від вашої особистої терпимості до болю. Наші майстри вміють зробити процес максимально комфортним і можуть зробити перерви, якщо це необхідно. Багато клієнтів описують це відчуття як легке печіння або подряпину.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						Скільки часу заживає татуювання?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Початковий процес загоєння зазвичай займає від 2 до 3 тижнів, протягом яких вам потрібно буде ретельно дотримуватися наших інструкцій щодо догляду. Повне загоєння, коли шкіра повністю відновлюється, може тривати до кількох місяців. Ми надамо вам детальні вказівки щодо догляду, щоб забезпечити оптимальне загоєння.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						Чи можу я принести власний дизайн?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Абсолютно! Ми вітаємо проекти, надані клієнтами, і працюватимемо з вами, щоб удосконалити та адаптувати їх за потреби. Наша мета — створити татуювання, яким ви будете повністю задоволені, незалежно від того, чи це дизайн, створений вами чи створений нами разом.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});